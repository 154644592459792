import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import NavigationBanner from '../components/NavigationBanner';
import { APP_STORE_LINK } from '../consts';
import './faq.scss';

const FaqPage = () => (
  <Layout selectedMenuItem="faq" banner={<NavigationBanner />} className="faq">
    <div className="row">
      <div className="col-lg-10 col-xl-8">
        <h1>FAQ</h1>

        <h2>About Sugarless</h2>

        <h3>What is Sugarless?</h3>
        <p>
          Sugarless is an iOS app, which helps you quit or reduce sugar in your life. It does
          so through daily logging, growing a virtual garden and guiding you on a journey of
          self-understanding. It utilizes principles from psychology and cognitive-behavioral
          therapy.
        </p>

        <h3>Is Sugarless for me?</h3>
        <p>
          Sugarless is for you if you feel that your sugar consumption habits are making your
          life worse. It&apos;s also for you if you like to learn about yourself, improve your
          health and establish positive habits.
        </p>

        <h3>Why should I reduce or quit sugar?</h3>
        <p>
          Scientific research shows that overconsumption of sugar can have many negative effects
          on your life. It could increase your risk of
          {' '}
          <a
            href="https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/1819573"
            target="_blank"
            rel="noreferrer"
          >
            heart disease
          </a>
          ,
          {' '}
          <a
            href="https://link.springer.com/article/10.1007/s11892-012-0259-6"
            target="_blank"
            rel="noreferrer"
          >
            type 2 diabetes
          </a>
          ,
          {' '}
          <a
            href="https://www.nature.com/articles/482027a"
            target="_blank"
            rel="noreferrer"
          >
            fatty liver disease
          </a>
          ,
          {' '}
          <a
            href="https://www.nejm.org/doi/full/10.1056/NEJMoa1215740"
            target="_blank"
            rel="noreferrer"
          >
            early onset dementia
          </a>
          ,
          {' '}
          <a
            href="https://bda.org/sugar"
            target="_blank"
            rel="noreferrer"
          >
            tooth decay
          </a>
          {' '}
          and
          {' '}
          <a
            href="https://jddonline.com/articles/dermatology/S1545961614P0428X"
            target="_blank"
            rel="noreferrer"
          >
            acne
          </a>
          . Many people also report that sugar makes them more moody, tired and emotionally
          unstable. Building up healthy sugar consumption habits has the potential to improve
          your life in many ways.
        </p>

        <h3>Can I use Sugarless for free?</h3>
        <p>
          Yes, the core functionality of Sugarless is free. This includes logging and monitoring
          your progress, growing a garden, and receiving daily tips and information. To unlock
          our structured self-exploration and habit changing program inspired by
          cognitive-behavioral therapy, you can purchase or subscribe to the Journey edition
          of the app.
        </p>

        <h3>What is Sugarless Journey?</h3>
        <p>
          Sugarless Journey is a structured, 20-step self-exploration and habit changing program
          inspired by cognitive-behavioral therapy. We have created it to help you maximize your
          chances of freeing yourself from sugar. To achieve that, it will help you to
          understand your motivations, triggers, cravings, and to form healthy reactions and
          coping mechanisms. You can purchase or subscribe to the Journey in the app.
        </p>

        <h3>Where can I download Sugarless?</h3>
        <p>
          You can download Sugarless in the
          {' '}
          <Link to={APP_STORE_LINK}>Apple App Store</Link>
          .
        </p>

        <h3>What devices can I use Sugarless on?</h3>
        <p>You can use Sugarless on iPhones and iPads with iOS 14 or higher.</p>

        <h2>Using Sugarless</h2>

        <h3>How to add a daily entry?</h3>
        <p>
          Open the Sugarless app and click the + button on the menu bar. Then select your answer
          based on whether you met your current goal.
        </p>
        <p>
          Alternatively, you can open the Calendar from the menu bar, hold your finger on
          the date for which you want to add an entry and select Update Log.
        </p>

        <h3>How to edit a daily entry?</h3>
        <p>
          Open the Sugarless app, select the Calendar from the menu bar, hold your finger on
          the date for which you want to edit the entry and select the new log status.
        </p>

        <h3>How to advance to the next Journey step?</h3>
        <p>
          To unlock the next Journey step, you need to log success in meeting your daily
          goals for 3 continuous days.
        </p>

        <h3>Why is my garden stagnating?</h3>
        <p>
          Your garden is stagnating, because you haven&apos;t met your goals or logged
          your progress for more than 2 out of the last 4 days. This will cause the
          trees to wither and eventually die. To make your garden thrive again, meet
          your goals for at least 2 consecutive days. Your withered trees will start
          healing and new ones will start growing again.
        </p>

        <h3>How to schedule daily logging reminders?</h3>
        <p>
          Open the Sugarless app and select the Profile from the menu bar. Click on
          the Schedule option in the NOTIFICATIONS section. Then make sure Notifications
          Enabled is on and Logging Prompt time is selected.
        </p>

        <h3>How to turn off notifications?</h3>
        <p>
          To turn off daily reminders, open the Sugarless app and select the Profile from the
          menu bar. Click on the Schedule option in the Notifications section. Then make sure
          Notifications Enabled is turned off.
        </p>
        <p>
          Alternatively, you can turn off notifications in your iOS by opening the Settings
          app, selecting Notifications, finding Sugarless app in the list, tapping on the
          option and making sure Allow Notifications is turned off. Here, you can also
          selectively turn off notification sounds and style.
        </p>

        <h3>How to restore Journey after reinstalling the app or moving to a new phone?</h3>
        <p>Open the Journey tab in the Sugarless app and tap RESTORE PURCHASES.</p>

        <h2>Managing Sugarless</h2>

        <h3>Is my data private with Sugarless?</h3>
        <p>
          Yes, we don&apos;t store your data outside of your own device. You can read more
          about it in our
          {' '}
          <Link to="/privacy">Privacy Policy</Link>
          .
        </p>

        <h3>How to cancel my subscription?</h3>
        <p>
          On your iPhone, open the Settings app, navigate to Apple ID - Subscriptions, select
          Sugarless and tap Cancel Subscription.
        </p>

        <h3>Can I get a refund?</h3>
        <p>
          You can request a refund from Apple by following the steps outlined in
          {' '}
          <Link
            to="https://support.apple.com/en-gb/HT204084"
          >
            Apple&apos;s Request a refund guide
          </Link>
          .
        </p>

        <h3>How can I contact you with a suggestion or a question?</h3>
        <p>
          You can contact us at
          {' '}
          <Link to="mailto:hello@sugarlessapp.com">hello@sugarlessapp.com</Link>
          {' '}
          or on the Sugarless Discord community. We are welcoming your suggestions, questions and
          personal stories!
        </p>

        <h3>How can I connect with the Sugarless community?</h3>
        <p>
          You can keep in touch with the community by joining our
          {' '}
          <a href="https://discord.gg/wY2heJtbyh">Discord server</a>
          {' '}
          or
          {' '}
          <a href="https://reddit.com/r/sugarless/">/r/sugarless subreddit</a>
          .
        </p>
      </div>
    </div>
  </Layout>
);

export default FaqPage;
